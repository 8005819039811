<template>
  <!--  考试信息-->
  <div class="exercisesType">
    <div style="display: flex; justify-content: space-between">
      <div class="menu_select">
        <h4 style="padding: 10px 24px 0px 24px">模块</h4>
        <a-menu style="width: 100%" mode="inline" v-model="menu">
          <a-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            @click="leftChange(item.id, item.name)"
            >{{ item.name }}</a-menu-item
          >
        </a-menu>
      </div>
      <div class="InfoTable">
        <p
          style="
            padding: 10px 0px 2px 16px;
            font-weight: 550;
            font-size: 15px;
            color: black;
          "
        >
          考试信息浏览记录
        </p>
        <div class="top_cont">
          <div class="EduRec-top-input">
            <span>日期：</span>
            <a-range-picker
              v-model="datePicker"
              style="margin-right: 15px; width: 220px"
              @change="onChange"
            />
          </div>
          <div class="EduRec-top-input">
            <a-button style="margin-right: 20px" @click="excel">
              <a-icon style="color: #00a854" type="file-excel" />
              导出该模块
            </a-button>
            <a-button @click="Allexcel">
              <a-icon style="color: #00a854" type="file-excel" />
              导出全部模块
            </a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="messageList"
          :rowKey="(record) => record.id"
          :pagination="false"
          style="margin: 15px"
        >
          <span slot="location" slot-scope="text, record">
            <span>{{ returnTitle(record.location) }}</span>
          </span>
        </a-table>
        <div
          style="
            margin-top: 28px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ total }}条记录 第 {{ pageNo }} /
            {{ Math.ceil(total / pageSum) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pageSum"
            :total="total"
            v-model="pageNo"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMoKuaiData,
  getMessageRecord,
  getAllMessageExcel,
  getMseeageExcel,
} from "@/service/titleExam_api";
import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
export default {
  data() {
    return {
      total: 0,
      id: "",
      ExelName: "",
      pageNo: 1,
      pageSum: 10,
      menuList: [],
      menu: [0],
      messageList: [],
      datePicker: null,
      startTime: "",
      endTime: "",
      columns: [
        {
          title: "姓名",
          width: "9%",
          dataIndex: "memberName",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          width: "20%",
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          width: "7%",
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          width: "10%",
        },
        {
          title: "标题",
          dataIndex: "title",
          width: "23%",
        },
        {
          title: "点击位置",
          scopedSlots: { customRender: "location" },
          width: "13%",
        },
        {
          title:"操作时间",
          width:"10%",
          dataIndex:"createdTime"
        }
      ],
    };
  },
  created() {},
  mounted() {
    this.getMenu();
  },
  methods: {
    returnTitle(code) {
      let list = codeList;
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].code) {
          return list[i].title;
        }
      }
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pageNo = 1;
      this.loading();
    },
    async getMenu() {
      let data = {
        page: 1,
        pageSize: 999,
      };
      const res = await getMoKuaiData(data);
      if (res.code == 200) {
        this.menuList = res.data;
        this.load();
      }
    },
    // 默认加载第一个模块内容
    async load() {
      // console.log("第一个", this.menuList);
      this.id = this.menuList[0].id;
      this.ExelName = this.menuList[0].name;
      let data = {
        examTypeId: this.menuList[0].id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      console.log(data);
      const res = await getMessageRecord(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    // 点击左侧菜单获取内容
    async leftChange(id, name) {
      this.id = id;
      this.ExelName = name;
      this.pageNo = 1;
      this.datePicker = null;
      this.startTime = "";
      this.endTime = "";
      let data = {
        examTypeId: this.id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      const res = await getMessageRecord(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },

    // // 分页查询
    // // 分页查询
    async pageChange(pageNo, pageSum) {
      this.pageNo = pageNo;
      this.pageSum = pageSum;
      this.loading();
    },
    async loading() {
      let data = {
        examTypeId: this.id,
        pageNo: this.pageNo,
        pageSum: this.pageSum,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      const res = await getMessageRecord(data);
      if (res.code == 0) {
        this.messageList = res.data;
        this.total = res.count;
      }
    },
    async excel() {
      let ExelName = this.ExelName + "考试信息浏览记录";
      let data = {
        examTypeId: this.id,
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: ExelName,
      };
      await getMseeageExcel(data);
    },
    async Allexcel() {
      let ExelName = "考试信息浏览记录";
      let data = {
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: ExelName,
      };
      await getAllMessageExcel(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.exercisesType {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .menu_select {
    background-color: white;
    width: 18%;
    min-height: 750px;
  }
  .InfoTable {
    background-color: white;
    width: 81%;
    min-height: 750px;
  }
}
.top_cont {
  width: 97%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
</style>
